import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { DarkkModeContextProvider } from './context/darkModeContext';

ReactDOM.render(
  <React.StrictMode>
    <DarkkModeContextProvider>
    <App />
    </DarkkModeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
