import "./login.scss"

const Login = () => {
    return (
        <div className="login">
            login
        </div>
    )
}

export default Login